import React from 'react';

import GmailIcon from '../images/gmail.svg';
import LinkedinIcon from '../images/linkedin.svg';
import InstagramIcon from '../images/instagram.svg';
import BehanceIcon from '../images/behance.svg';

import './social-links.css';

export default () => (
  <div className="social-links">
    <a
      href="mailto:vilija.valeisaite@gmail.com"
      rel="noopener noreferrer"
      className="social-links__link"
    >
      <GmailIcon />
    </a>

    <a
      href="https://www.linkedin.com/in/vilija-valeisaite-1a30bb64"
      target="_blank"
      rel="noopener noreferrer"
      className="social-links__link"
    >
      <LinkedinIcon />
    </a>

    <a
      href="https://www.instagram.com/vilija_valeisaite"
      target="_blank"
      rel="noopener noreferrer"
      className="social-links__link"
    >
      <InstagramIcon />
    </a>

    <a
      href="https://www.behance.net/VilijaValeisaite"
      target="_blank"
      rel="noopener noreferrer"
      className="social-links__link"
    >
      <BehanceIcon />
    </a>
  </div>
);
