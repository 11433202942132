import React from 'react';

import './logo.css';

export default () => (
  <div className="logo">
    <div className="logo__initials">
      <div className="logo__initials_first">V</div>
      <div className="logo__separator" />
      <div className="logo__initials_last">V</div>
    </div>
    <div className="logo__name">Vilija Valeisaite</div>
    <div className="logo__role">Graphic Designer / Photographer</div>
  </div>
);
